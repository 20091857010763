import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Autocomplete, debounce, Grid, InputAdornment, SelectChangeEvent, TextField} from "@mui/material";
import Recipe from "domain/models/recipes/Recipe";
import RecipeCard from "components/recipes/RecipeCard";
import MultipleSelectChip from "components/commons/MultipleSelectChip";
import {useTranslation} from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import RecipeSkeletonCard from "components/recipes/RecipeSkeletonCard";

interface RecipesGridProps {
  loading: boolean;
  recipes: Recipe[];
  isSelected?: (recipe: Recipe) => boolean;
  handleDelete?: (recipe: Recipe) => void;
  handleEdit?: (recipe: Recipe) => void;
  handleClick?: (recipe: Recipe) => void;
}

const RecipesGrid = (props: RecipesGridProps): JSX.Element => {

  const {t} = useTranslation();

  const [tags, setTags] = useState<Set<string>>(new Set());

  const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([]);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    const existingTags = new Set(props.recipes.flatMap(recipe => recipe.tags));
    setTags(existingTags);
    setSelectedTags((selectedTags) => selectedTags.filter(tag => existingTags.has(tag)));
  }, [props.recipes, setSelectedTags, setTags]);

  useEffect(() => {
    setAutocompleteOptions(Array.from(new Set(props.recipes.map(recipe => recipe.name))));
  }, [props.recipes, setAutocompleteOptions]);

  useEffect(() => {

    const cleanedSearchWords = searchText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ")
        .map(word => word.trim())
        .filter(word => word.length > 0);

    setFilteredRecipes(props.recipes
        .filter(recipe => {
          if (selectedTags.length === 0) {
            return true;
          }
          const recipeTags = new Set(recipe.tags);
          return selectedTags.every(selectedTag => recipeTags.has(selectedTag));
        })
        .filter(recipe => {
          if (cleanedSearchWords.length === 0) {
            return true;
          }
          const cleanedRecipeName = recipe.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          return cleanedSearchWords.every(cleanedSearchText => cleanedRecipeName.toLowerCase().includes(cleanedSearchText));
        })
    );
  }, [props.recipes, selectedTags, setFilteredRecipes, searchText]);

  const handleTagsChange = useCallback((event: SelectChangeEvent<string[]>) => {
    const {
      target: {
        value
      }
    } = event;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  }, [setSelectedTags]);

  const handleSearchTextChange = (event: React.SyntheticEvent, searchInput: string | null) => {
    setSearchText(searchInput ?? "");
  };
  const debouncedSearchTextChangeHandler = useMemo(() => debounce(handleSearchTextChange, 200), []);

  return (
      <Grid
          container
          item
          spacing={3}
      >
        <Grid item xs={12} sm={6} xl={3}>
          <Autocomplete
              id="recipe-searchbar"
              freeSolo
              options={autocompleteOptions}
              onInputChange={debouncedSearchTextChangeHandler}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label={t("recipes.search")}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon/>
                            </InputAdornment>
                        )
                      }}
                  />
              )}
          />
        </Grid>
        {tags.size > 0 && (
            <Grid item xs={12} sm={6} xl={3}>
              <MultipleSelectChip
                  label={t("recipes.tags.label")}
                  uniqueIdPrefix="recipe-tags"
                  allValues={tags}
                  selectedValues={selectedTags}
                  handleChange={handleTagsChange}
              />
            </Grid>
        )}
        <Grid
            item
            container
            direction="row"
            spacing={3}
        >
          {props.loading ?
              Array.from(Array(12).keys()).map((n: number) => (
                  <Grid
                      key={n}
                      item
                      xs={12} md={6} lg={4} xl={2}>
                    <RecipeSkeletonCard/>
                  </Grid>
              )) :
              filteredRecipes.map((recipe: Recipe) => (
                  <Grid
                      key={recipe.id}
                      item
                      xs={12} md={6} lg={4} xl={2}>
                    <RecipeCard
                        recipe={recipe}
                        handleDelete={props.handleDelete}
                        handleClick={props.handleClick}
                        handleEdit={props.handleEdit}
                        isSelected={props.isSelected}
                    />
                  </Grid>
              ))
          }
        </Grid>
      </Grid>
  );
};

export default RecipesGrid;
