import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import AppContent from "./components/AppContent";
import CustomThemeProvider from "./CustomThemeProvider";
import {CircularProgress, Container, Grid} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

const App = (): JSX.Element => {

  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && !isLoading) {
        await loginWithRedirect();
      }
    })();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated || isLoading) {
    return (
        <Container maxWidth="xs" disableGutters>
          <Grid container justifyContent="center" alignItems="center" sx={{height: "200px"}}>
            <Grid item>
              <CircularProgress role="status"/>
            </Grid>
          </Grid>
        </Container>
    );
  }

  return (
      <CustomThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AppContent/>
        </LocalizationProvider>
      </CustomThemeProvider>
  );
};

export default App;
