import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
  en: {
    translation: {
      pantree: "Pantree",
      loading: "Loading",
      logout: "Logout",
      actions: {
        delete: "Delete",
        edit: "Edit",
        logout: "Logout",
        add: "Add",
        save: "Save",
        cancel: "Cancel",
        generate: "Generate",
      },
      welcome: "Welcome",
      menu: {
        "recipes": "Recipes",
        "mealPlanner": "Meal planner",
        "groceries": "Grocery lists",
      },
      recipes: {
        recipe: "Recipe",
        search: "Search recipes",
        tags: {
          label: "Tags",
          placeholder: "Add a tag and press enter",
        },
        ingredients: {
          label: "Ingredients",
          placeholder: "Add an ingredient and press enter",
        },
        imageUrl: {
          label: "Image URL"
        },
      },
      groceryLists: {
        defaultName: "Grocery list",
        groceryList: "Grocery list",
        groceryListItem: "Item",
        sourceRecipes_one: "For recipe: {{recipes}}",
        sourceRecipes_other: "For recipes: {{recipes}}"
      },
      mealPlan: {
        labelWithDate: "Meal plan for the {{date}}",
        generateMealPlan: "Generate meal plan",
        configureAndGenerateMealPlan: "Configure & generate meal plan",
        generateGroceryList: "Generate grocery list",
        automaticallyGenerateGroceryList: "Automatically generate grocery list",
      },
      meaPlanGeneration: {
        from: "Generate from",
        to: "To",
        daysToGenerate: "Days to generate",
        mealPerDay: "Meal per day",
        repeatDelay: "Days to wait before repeating a recipe",
      },
      name: "Name",
      week: "Week",
      month: "Month",
    }
  },
};

(async () => {
  await i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
          escapeValue: false
        },
      });
})();