import {ThemeProvider} from "@mui/material/styles";
import React, {PropsWithChildren} from "react";
import {createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e65100",
    },
    secondary: {
      main: "#074f46"
    },
    mode: "dark",
    background: {
      paper: "#1c1c1c"
    }
  },
});

const CustomThemeProvider = (props: PropsWithChildren): JSX.Element => {
  return (
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
  );
};

export default CustomThemeProvider;