import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from "react";
import {TextField} from "@mui/material";

interface MaterialTextFieldProps {
  id: string;
  name: string;
  value?: string;
  label: string;

  onChange?(name: string, value: string): Promise<void>;

  onBlur?(): Promise<void>;

  handleEnterKeyDown?(value: string): void;

  clearOnKeyDown?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  placeholder?: string;
}

const MaterialTextField = (props: MaterialTextFieldProps): JSX.Element => {

  const [value, setValue] = useState<string | undefined>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value, setValue]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue: any = e.target.value;
    setValue(newValue);
    if (props.onChange) {
      await props.onChange(props.name, newValue);
    }
  };

  const handleKeyDown = (e?: KeyboardEvent<HTMLInputElement>) => {

    if (!props.handleEnterKeyDown) {
      return;
    }

    if (e && e.key !== "Enter") {
      return;
    }
    if (!value) {
      return;
    }
    if (props.multiline && value.length > 0 && value.charAt(value.length - 1) !== "\n") {
      return;
    }
    const newValue = value.trim();
    if (props.clearOnKeyDown) {
      setValue("");
    }

    props.handleEnterKeyDown(newValue);

    if (e) {
      e.preventDefault();
    }
  };

  const handleBlur = async () => {
    if (props.onBlur) {
      await props.onBlur();
    } else {
      handleKeyDown();
    }
  };

  return (
      <TextField
          id={`${props.id}-input`}
          label={props.label}
          value={value}
          name={props.name}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
          multiline={props.multiline}
          autoFocus={props.autoFocus}
          fullWidth
          placeholder={props.placeholder}
      />
  );
};

export default MaterialTextField;