import {Button, Card} from "@mui/material";
import React, {useCallback} from "react";
import {buildGroceryListItem, GroceryList, GroceryListItem} from "domain/models/groceries/GroceryList";
import {useGetGroceryList} from "hooks/groceries/grocery-lists-hooks";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import {useTranslation} from "react-i18next";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import {useAuthToken} from "hooks/use-auth-token";
import GroceryListItemsList from "components/groceries/GroceryListItemsList";

interface GroceryListsDetailsProps {
  selectedGroceryListId: string | null;

  onSave(groceryList: GroceryList): void;
}

const GroceryListsDetails = ({selectedGroceryListId, onSave}: GroceryListsDetailsProps): JSX.Element => {

  const token: string | null = useAuthToken();
  const {t} = useTranslation();

  const [groceryList, setGroceryList] = useGetGroceryList(selectedGroceryListId);

  const handleUpdate = useCallback(async (newItems: GroceryListItem[]) => {
    if (groceryList === null) {
      return;
    }
    const newGroceryList = {
      ...groceryList,
      groceries: newItems
    };
    await onSave(newGroceryList);
    setGroceryList(newGroceryList);
  }, [groceryList, onSave, setGroceryList]);

  const handleNew = useCallback(async () => {
    if (token === null) {
      return;
    }
    if (groceryList === null) {
      return;
    }
    try {
      const updatedGroceryList = {
        ...groceryList,
        groceries: [buildGroceryListItem(), ...groceryList.groceries]
      };
      const groceryListUpdated: GroceryList | null = await new GroceryListsApi(token)
          .updateEntity(updatedGroceryList);
      setGroceryList(groceryListUpdated);
    } catch (error) {
      console.error(error);
    }
  }, [token, groceryList, setGroceryList]);

  return (
      <>
        {selectedGroceryListId && (
            <Grid
                container
                direction="column"
                spacing={2}
                justifyContent="flex-end"
            >
              <Grid item xs="auto">
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={handleNew}
                >
                  {t("groceryLists.groceryListItem")}
                </Button>
              </Grid>

              <Grid item xs>
                {groceryList && groceryList.groceries.length > 0 && (
                    <Card>
                      <GroceryListItemsList
                          items={groceryList.groceries}
                          handleUpdate={handleUpdate}
                      />
                    </Card>
                )}
              </Grid>
            </Grid>
        )}
      </>
  );
};

export default GroceryListsDetails;