import * as React from "react";
import {Theme, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(value: string, selectedValues: readonly string[], theme: Theme) {
  return {
    fontWeight: selectedValues.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultipleSelectChipProps {
  uniqueIdPrefix: string;
  label: string;
  allValues: Set<string>;
  selectedValues: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
}

const MultipleSelectChip = (props: MultipleSelectChipProps): JSX.Element => {

  const theme = useTheme();

  return (
      <FormControl sx={{width: "100%"}}>
        <InputLabel id={`${props.uniqueIdPrefix}-label`}>{props.label}</InputLabel>
        <Select
            labelId={`${props.uniqueIdPrefix}-label`}
            id={props.uniqueIdPrefix}
            multiple
            value={props.selectedValues}
            onChange={props.handleChange}
            input={<OutlinedInput id={`select-${props.uniqueIdPrefix}`} label="Chip"/>}
            renderValue={(selected) => (
                <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                  {selected.map((value) => (<Chip key={value} label={value}/>))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
          {Array.from(props.allValues).map((value) => (
              <MenuItem
                  key={value}
                  value={value}
                  style={getStyles(value, props.selectedValues, theme)}
              >
                {value}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default MultipleSelectChip;
