import Grid from "@mui/material/Grid";
import React, {useCallback, useEffect, useState} from "react";
import {Hidden, Snackbar} from "@mui/material";
import {GroceryList, validateGroceryList} from "domain/models/groceries/GroceryList";
import {useAuthToken} from "hooks/use-auth-token";
import {useSnackbar} from "hooks/use-snackbar";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import {useHistory, useParams} from "react-router-dom";
import GroceryListsMaster from "components/groceries/GroceryListsMaster";
import GroceryListsDetails from "components/groceries/GroceryListsDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrimaryFab from "components/commons/PrimaryFab";

interface GroceryListsMasterDetailsViewParams {
  groceryListId?: string;
}

const GroceryListsMasterDetailsView = (): JSX.Element => {

  const token: string | null = useAuthToken();

  const history = useHistory();

  const {groceryListId} = useParams<GroceryListsMasterDetailsViewParams>();
  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();

  const [selectedGroceryListId, setSelectedGroceryListId] = useState<string | null>(null);

  const handleClick = useCallback((groceryList: GroceryList) => {
    if (groceryList.id) {
      setSelectedGroceryListId(groceryList.id);
      history.push(`/groceryLists/${groceryList.id}`);
    }
  }, [setSelectedGroceryListId, history]);

  const handleBack = useCallback(() => {
    setSelectedGroceryListId(null);
    history.replace('/groceryLists');
  }, [setSelectedGroceryListId, history]);

  useEffect(() => {
    if (token === null) {
      return;
    }
    if (groceryListId === undefined) {
      setSelectedGroceryListId(null);
      return;
    }
    (async () => {
      try {
        const groceryList: GroceryList | null = await new GroceryListsApi(token).getEntity(groceryListId);
        if (groceryList === null) {
          setSelectedGroceryListId(null);
          history.replace('/groceryLists');
        } else {
          setSelectedGroceryListId(groceryList.id ?? null);
        }
      } catch (error) {
        setSelectedGroceryListId(null);
        history.replace('/groceryLists');
      }
    })();
  }, [history, token, groceryListId]);

  useEffect(() => {
    if (selectedGroceryListId == null) {
      history.replace('/groceryLists');
    } else {
      if (history.location.pathname === `/groceryLists/${selectedGroceryListId}`) {
        history.replace(`/groceryLists/${selectedGroceryListId}`);
      }
    }
  }, [history, selectedGroceryListId]);

  const saveSelectedList = async (groceryList: GroceryList): Promise<void> => {
    if (token == null) {
      console.error("The auth token is null");
      return;
    }

    if (groceryList === null || !groceryList.id) {
      return;
    }

    try {
      validateGroceryList(groceryList);
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
      return;
    }

    try {
      const groceryListsApi = new GroceryListsApi(token);
      if (groceryList.id) {
        await groceryListsApi.updateEntity(groceryList);
      }
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
    }
  };

  return (
      <>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />

        <Hidden mdDown>

          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <GroceryListsMaster
                  autoSelect={true}
                  selectedGroceryListId={selectedGroceryListId}
                  onClick={handleClick}
                  onSave={saveSelectedList}
              />
            </Grid>

            <Grid item xs={12} md={7} lg={8} xl={9}>
              <GroceryListsDetails
                  selectedGroceryListId={selectedGroceryListId}
                  onSave={saveSelectedList}
              />
            </Grid>
          </Grid>

        </Hidden>

        <Hidden mdUp>
          {
            selectedGroceryListId === null
                ? (<GroceryListsMaster
                    autoSelect={false}
                    selectedGroceryListId={null}
                    onClick={handleClick}
                    onSave={saveSelectedList}
                />)
                : (<>
                  <GroceryListsDetails
                      selectedGroceryListId={selectedGroceryListId}
                      onSave={saveSelectedList}
                  />
                  <PrimaryFab onClick={handleBack}>
                    <ArrowBackIcon/>
                  </PrimaryFab>
                </>)
          }
        </Hidden>
      </>
  )
};

export default GroceryListsMasterDetailsView;
