import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Button, Grid, Snackbar} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useTranslation} from "react-i18next";
import Recipe from "domain/models/recipes/Recipe";
import {useGetRecipes} from "hooks/recipes/recipes-hooks";
import {RecipesApi} from "services/apis/RecipesApi";
import {useAuthToken} from "hooks/use-auth-token";
import {useSnackbar} from "hooks/use-snackbar";
import RecipesGrid from "components/recipes/RecipesGrid";

const RecipesView = (): JSX.Element => {

  const {t} = useTranslation();
  const token: string | null = useAuthToken();
  const history = useHistory();

  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();

  const [recipes, setRecipes, loadingRecipes] = useGetRecipes();

  const handleNew = useCallback(() => {
    history.push("/recipes/edit");
  }, [history]);

  const handleClick = useCallback((recipe: Recipe) => {
    if (recipe.id) {
      history.push(`/recipes/edit/${recipe.id}`);
    }
  }, [history]);

  const handleDelete = useCallback(async (deletedRecipe: Recipe) => {
    if (token === null) {
      return;
    }

    if (deletedRecipe.id) {
      setRecipes(recipes.filter(recipe => recipe.id !== deletedRecipe.id));
      try {
        await new RecipesApi(token).deleteEntity(deletedRecipe.id);
      } catch (error) {
        if (error instanceof Error) {
          showSnackbar(error.message);
        }
        setRecipes(recipes);
      }
    }
  }, [token, showSnackbar, setRecipes, recipes]);

  return (
      <>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={closeSnackbar}
            message={snackbarState.message}
            key={snackbarState.message}
        />
        <Grid
            container
            spacing={2}
        >
          <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent="flex-end"
          >
            <Grid item xs="auto">
              <Button
                  variant="contained"
                  startIcon={<AddIcon/>}
                  onClick={handleNew}
              >
                {t("recipes.recipe")}
              </Button>
            </Grid>
          </Grid>

          <RecipesGrid
              loading={loadingRecipes}
              recipes={recipes}
              handleDelete={handleDelete}
              handleClick={handleClick}
              handleEdit={handleClick}
          />
        </Grid>
      </>
  );
};

export default RecipesView;
