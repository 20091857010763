import {CrudApi, PaginatedEntitiesList} from "services/apis/CrudApi";
import {GroceryList} from "domain/models/groceries/GroceryList";

export class GroceryListsApi extends CrudApi<GroceryList> {
  constructor(token: string) {
    super(token, "groceryLists");
  }

  public async getEntities(): Promise<GroceryList[]> {
    const groceryLists: GroceryList[] = await super.getEntities();
    groceryLists.sort((a, b) => -a.creationDate.localeCompare(b.creationDate));
    return groceryLists;
  }

  public async getPaginatedEntities(offset: number, limit: number): Promise<PaginatedEntitiesList<GroceryList>> {
    return await super.getPaginatedEntities(offset, limit);
  }

  public async createEntity(entity: GroceryList): Promise<GroceryList> {
    return await super.createEntity(entity);
  }

  public async getEntity(id: string): Promise<GroceryList | null> {
    return await super.getEntity(id);
  }

  public async updateEntity(entity: GroceryList): Promise<GroceryList | null> {
    return await super.updateEntity(entity);
  }

  public async deleteEntity(id: string): Promise<void> {
    await super.deleteEntity(id);
  }
}
