import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {auth0Config} from "config";

export const useAuthToken = (): string | null => {
  const [token, setToken] = useState<string | null>(null);
  const {isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup, logout, user} = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated || isLoading) {
        return;
      }
      try {
        let token: string = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });
        setToken(token);
      } catch {
        try {
          let token = await getAccessTokenWithPopup({
            audience: auth0Config.audience,
          });
          setToken(token);
        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          }
          await logout();
        }
      }
    })();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, logout, setToken]);

  useEffect(() => {
    if (token) {
      console.log("token", token);
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      console.log("user.sub", user.sub);
    }
  }, [user]);

  return token;
};