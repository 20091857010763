import React, {useEffect, useState} from "react";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import {useAuthToken} from "hooks/use-auth-token";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "hooks/use-snackbar";
import {Box, Button, Card, CardContent, CardHeader, Container, Grid, Snackbar, TextField} from "@mui/material";
import {useGetDailyMealPlans} from "hooks/plans/meal-plans-hooks";
import {MobileDatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment/moment";
import {buildDefaultGroceryListGeneratorOptions, GroceryListGeneratorOptions} from "domain/logic/grocery-lists/GroceryListGenerator";
import {buildGroceryList, buildGroceryListItem, buildGroceryListName, GroceryList, GroceryListItem} from "domain/models/groceries/GroceryList";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import AddIcon from "@mui/icons-material/Add";
import GroceryListItemsList from "components/groceries/GroceryListItemsList";

const GroceryListGenerationView = (): JSX.Element => {

  const token: string | null = useAuthToken();

  const {t} = useTranslation();
  const history = useHistory();
  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();

  const [mealPlans] = useGetDailyMealPlans();

  const [mealPlansToProcess, setMealPlansToProcess] = useState<DailyMealPlan[]>([]);
  const [itemsMap, setItemsMap] = useState<Map<string, GroceryListItem[]>>(new Map());

  const [options, setOptions] = useState<GroceryListGeneratorOptions>(buildDefaultGroceryListGeneratorOptions());

  useEffect(() => {
    setMealPlansToProcess(mealPlans
        .filter(mealPlan => moment(mealPlan.date).isSameOrAfter(options.from)
            && moment(mealPlan.date).isSameOrBefore(options.to)
        )
    );
  }, [mealPlans, options]);

  useEffect(() => {
    setItemsMap(oldItemsMap => {
      const newMealPlanIds = new Set(mealPlansToProcess.map(mealPlan => mealPlan.id));
      const newItemsMap = new Map(oldItemsMap);
      Array.from(newItemsMap.keys())
          .filter(key => !newMealPlanIds.has(key))
          .forEach(key => newItemsMap.delete(key));

      for (const mealPlan of mealPlansToProcess) {
        if (mealPlan.id && !newItemsMap.has(mealPlan.id)) {
          newItemsMap.set(
              mealPlan.id,
              mealPlan.recipe.ingredients
                  .map(ingredient => buildGroceryListItem(ingredient, [mealPlan.recipe.name]))
          );
        }
      }
      return newItemsMap;
    });

  }, [mealPlansToProcess]);

  const handleChange = async (name: string, value: any): Promise<void> => {
    setOptions({
      ...options,
      [name]: value
    });
  };

  const handleDateChange = async (name: string, value: Moment | null): Promise<void> => {
    if (value !== null) {
      await handleChange(name, value);
    }
  };

  const handleSave = async (): Promise<void> => {

    const groceryList: GroceryList = buildGroceryList(
        buildGroceryListName(options),
        Array.from(itemsMap.values()).flatMap(items => items)
    );

    try {
      await saveGroceryList(groceryList);
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
    }
  };

  const saveGroceryList = async (groceryList: GroceryList) => {
    if (token === null) {
      return;
    }
    const savedGroceryList: GroceryList = await new GroceryListsApi(token)
        .createEntity(groceryList);

    history.push(`/groceryLists/${savedGroceryList.id}`);
  };

  const updateItems = async (mealPlan: DailyMealPlan, items: GroceryListItem[]) => {
    setItemsMap(map => {
      const newMap = new Map(map);
      if (mealPlan.id === undefined) {
        return newMap;
      }
      newMap.set(mealPlan.id, items);
      return newMap;
    });
  };

  const addItem = async (mealPlan: DailyMealPlan) => {
    if (mealPlan.id === undefined) {
      return;
    }
    const items = itemsMap.get(mealPlan.id) ?? [];
    items.push(buildGroceryListItem("???", [mealPlan.recipe.name]));
    await updateItems(mealPlan, items);
  };

  return (
      <Container>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />
        <form>
          <Grid
              container
              direction="column"
              spacing={3}
          >
            <Grid item>
              <Card>
                <CardContent>
                  <Grid container
                        direction="row"
                        spacing={3}>
                    <Grid item xs={6}>
                      <MobileDatePicker
                          label={t("meaPlanGeneration.from")}
                          inputFormat="YYYY-DD-MM"
                          value={options.from}
                          onChange={(value) => handleDateChange("from", value)}
                          renderInput={(params) => <TextField {...params} fullWidth/>}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MobileDatePicker
                          label={t("meaPlanGeneration.to")}
                          inputFormat="YYYY-DD-MM"
                          value={options.to}
                          onChange={(value) => handleDateChange("to", value)}
                          renderInput={(params) => <TextField {...params} fullWidth/>}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid
                item
                container
                direction="column"
                spacing={3}
            >
              {mealPlansToProcess.map(mealPlan => (
                  <Grid item key={mealPlan.id}>
                    <Card>
                      <CardHeader
                          titleTypographyProps={{variant: "h6"}}
                          title={`${moment(mealPlan.date).format("L")} - ${mealPlan.recipe.name}`}/>
                      <CardContent>
                        <Button
                            startIcon={<AddIcon/>}
                            variant="text"
                            onClick={async () => await addItem(mealPlan)}
                        >
                          {t("groceryLists.groceryListItem")}
                        </Button>
                        <GroceryListItemsList
                            items={itemsMap.get(mealPlan.id ?? "") ?? []}
                            handleUpdate={async (items) => await updateItems(mealPlan, items)}
                        />
                      </CardContent>
                    </Card>

                  </Grid>
              ))}
            </Grid>
            <Grid item>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
              >
                <Box sx={{transform: "none"}}>
                  <Button
                      variant="contained"
                      onClick={handleSave}
                  >
                    {t("actions.generate")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
  );
};

export default GroceryListGenerationView;
