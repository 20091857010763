import {DailyMealPlan} from "domain/models/plan/MealPlan";
import moment, {Moment} from "moment/moment";
import Recipe from "domain/models/recipes/Recipe";

export interface MealPlanGeneratorOptions {
  from: Moment;
  to: Moment;
  mealPerDay: number;
  repeatDelay: number;
}

export const buildDefaultMealPlanGeneratorOptions = (from: Moment = moment().startOf("day")): MealPlanGeneratorOptions => {
  return {
    from: moment(from),
    to: moment(from).add(2, "days"),
    mealPerDay: 2,
    repeatDelay: 7,
  };
};

const defaultOptions: MealPlanGeneratorOptions = Object.freeze(
    buildDefaultMealPlanGeneratorOptions()
);

export default class MealPlanGenerator {

  private readonly recipes: Recipe[];
  private unusedRecipes: Recipe[];

  constructor(recipes: Recipe[]) {
    this.recipes = recipes;
    this.unusedRecipes = [...recipes];
  }

  public generateMealPlan(
      currentPlan: DailyMealPlan[] = [],
      options: MealPlanGeneratorOptions = defaultOptions
  ): DailyMealPlan[] {

    const currentMealPlan: DailyMealPlan[] = [...currentPlan];
    currentMealPlan.sort((thisPlan, thatPlan) => moment(thisPlan.date).diff(moment(thatPlan.date), "days"));

    this.initUsedRecipes(options.from, options.to, currentMealPlan, options);

    const newMeals: DailyMealPlan[] = [];

    let currentDay = moment(options.from);
    let currentMealIndex = 0;
    while (currentMealIndex < currentMealPlan.length && moment(currentMealPlan[currentMealIndex].date).isBefore(currentDay)) {
      ++currentMealIndex;
    }

    while (currentDay.isSameOrBefore(options.to)) {
      while (currentMealIndex < currentMealPlan.length && moment(currentMealPlan[currentMealIndex].date).isBefore(currentDay)) {
        this.addUsedRecipe(currentMealPlan[currentMealIndex].recipe);
        ++currentMealIndex;
      }

      let mealCount = 0;
      while (currentMealIndex < currentMealPlan.length && moment(currentMealPlan[currentMealIndex].date).isSame(currentDay, "day")) {
        this.addUsedRecipe(currentMealPlan[currentMealIndex].recipe);
        ++mealCount;
        ++currentMealIndex;
      }

      for (; mealCount < options.mealPerDay; ++mealCount) {
        newMeals.push(this.generateDailyMealPlan(moment(currentDay)));
      }
      currentDay.add(1, "day");
    }
    return newMeals;
  }

  private initUsedRecipes(
      from: Moment,
      to: Moment,
      sortedMealPlan: DailyMealPlan[],
      options: MealPlanGeneratorOptions) {

    const repeatThreshold = moment(from).subtract(options.repeatDelay, "day");
    for (const mealPlan of sortedMealPlan) {
      if (moment(mealPlan.date).isSameOrAfter(repeatThreshold)) {
        if (moment(mealPlan.date).isSameOrBefore(to)) {
          this.addUsedRecipe(mealPlan.recipe);
        } else {
          break;
        }
      }
    }
  }

  private addUsedRecipe(usedRecipe: Recipe) {
    this.unusedRecipes = this.unusedRecipes.filter(recipe => usedRecipe.id !== recipe.id);
  }

  private generateDailyMealPlan(currentDay: Moment): DailyMealPlan {
    return {
      date: currentDay.toISOString(),
      recipe: this.getRandomRecipe()
    } as DailyMealPlan;
  }

  private getRandomRecipe(): Recipe {
    if (this.recipes.length === 0) {
      throw new Error("No recipes found");
    }
    if (this.unusedRecipes.length === 0) {
      this.unusedRecipes = [...this.recipes];
    }

    const randomIndex = Math.floor(Math.random() * this.unusedRecipes.length);
    const randomRecipe = this.unusedRecipes[randomIndex];
    this.unusedRecipes.splice(randomIndex, 1);
    return randomRecipe;
  }
}