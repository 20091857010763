import {Checkbox, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField} from "@mui/material";
import React, {ChangeEvent, KeyboardEvent, useCallback, useState} from "react";
import {GroceryListItem} from "domain/models/groceries/GroceryList";
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

interface GroceryListItemListProps {
  items: GroceryListItem[];
  handleUpdate: (items: GroceryListItem[]) => Promise<void>;
}

const GroceryListItemsList = ({items, handleUpdate}: GroceryListItemListProps): JSX.Element => {

  const {t} = useTranslation();

  const [editedIndex, setEditedIndex] = useState<number | null>(null);
  const [editedItem, setEditedItem] = useState<GroceryListItem | null>(null);

  const resetEditedItem = useCallback(() => {
    setEditedIndex(null);
    setEditedItem(null);
  }, [setEditedIndex, setEditedItem]);

  const update = useCallback(async (items: GroceryListItem[]) => {
    await handleUpdate(items);
    resetEditedItem();
  }, [handleUpdate, resetEditedItem]);

  const handleApplyChanges = async () => {
    if (editedItem === null || editedIndex === null) {
      return;
    }
    const newItems = [...items];
    newItems[editedIndex] = editedItem;

    await update(newItems);
    resetEditedItem();
  };

  const handleCheckChange = async (index: number) => {

    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      checked: !newItems[index].checked
    };

    await update(newItems);
  };

  const handleDelete = useCallback(async (index: number) => {

    const groceries = [...items];
    groceries.splice(index, 1)

    await update(groceries);

  }, [items, update]);

  const handleEdit = useCallback(async (index: number) => {
    setEditedIndex(index);
    setEditedItem(items[index]);
  }, [setEditedItem, setEditedIndex, items]);

  const handleNameChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setEditedItem(currentItem => {
      if (currentItem === null) {
        return null;
      }

      const newValue: string = e.target.value;
      return {
        ...currentItem,
        name: newValue
      };
    });
  }, [setEditedItem]);

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e && e.key === "Enter") {
      await handleApplyChanges();
    }
  };

  return (
      <List>
        {items.map((item, index) => (
            <ListItem
                key={index}
                disablePadding
                secondaryAction={<>
                  {(editedIndex !== null && editedIndex === index)
                      ? (
                          <IconButton
                              aria-label="save"
                              onClick={async () => await handleApplyChanges()}
                          >
                            <SaveIcon/>
                          </IconButton>
                      )
                      : (
                          <IconButton
                              aria-label="edit"
                              onClick={() => handleEdit(index)}
                          >
                            <EditIcon/>
                          </IconButton>
                      )
                  }
                  <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(index)}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </>}
            >
              <ListItemButton onClick={() => handleEdit(index)}>
                <ListItemIcon>
                  <Checkbox
                      edge="start"
                      checked={item.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{'aria-labelledby': `item-${index}`}}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleCheckChange(index)
                      }}
                  />
                </ListItemIcon>
                {(editedIndex !== null && editedItem !== null && editedIndex === index)
                    ? (
                        <TextField
                            id={`item-edit-${index}`}
                            variant="standard"
                            value={editedItem.name}
                            onChange={handleNameChange}
                            onKeyDown={async (e: KeyboardEvent) => await handleKeyDown(e)}
                            onBlur={async () => await handleApplyChanges()}
                        />
                    )
                    : (
                        <ListItemText
                            id={`item-${index}`}
                            primary={item.name}
                            secondary={
                              item.sourceRecipes.length > 0
                                  ? t("groceryLists.sourceRecipes", {
                                    count: item.sourceRecipes.length,
                                    recipes: item.sourceRecipes.map((recipeName) => recipeName).join(", ")
                                  })
                                  : ""
                            }
                        />
                    )
                }
              </ListItemButton>
            </ListItem>
        ))}
      </List>
  );
};

export default GroceryListItemsList;