import RestaurantIcon from "@mui/icons-material/Restaurant";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import {IconType} from "domain/models/navigation-menu/IconType";

interface MenuIconProps {
  iconType: IconType;
}

const IconSwitch = ({iconType}: MenuIconProps): JSX.Element => {

  switch (iconType) {
    case IconType.Restaurant:
      return (
          <RestaurantIcon/>
      );
    case IconType.RestaurantMenu:
      return (
          <RestaurantMenuIcon/>
      );
    case IconType.MenuBook:
      return (
          <MenuBookIcon/>
      );
    case IconType.LocalGroceryStore:
      return (
          <LocalGroceryStoreIcon/>
      );
    default:
      return (<></>);
  }
};

export default IconSwitch;