import {useAuthToken} from "hooks/use-auth-token";
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import {GroceryList} from "domain/models/groceries/GroceryList";
import {PaginatedEntitiesList} from "services/apis/CrudApi";

export const useGetGroceryLists = (): [GroceryList[], Dispatch<SetStateAction<GroceryList[]>>] => {

  const [result, setResult] = useState<GroceryList[]>([]);
  const token = useAuthToken();

  useEffect(() => {
    if (token == null) {
      return;
    }
    (async () => {
      try {
        const groceryLists = await new GroceryListsApi(token).getEntities();
        setResult(groceryLists);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    })();
  }, [token, setResult]);

  return [result, setResult];
};

export const useGetPaginatedGroceryLists = (
    offset: number,
    limit: number
): [PaginatedEntitiesList<GroceryList>, Dispatch<SetStateAction<PaginatedEntitiesList<GroceryList>>>, () => Promise<void>, boolean, boolean] => {

  const [result, setResult] = useState<PaginatedEntitiesList<GroceryList>>({
    list: [],
    offset: 0,
    limit: 0,
    total: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const token = useAuthToken();

  const refresh = useCallback(async () => {
    if (token == null) {
      return;
    }
    setLoading(true);
    try {
      const groceryLists = await new GroceryListsApi(token).getPaginatedEntities(offset, limit);
      setResult(groceryLists);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
      setFirstLoading(false);
    }
  }, [limit, offset, setLoading, token]);

  useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, [refresh]);

  return [result, setResult, refresh, loading, firstLoading];
};

export const useGetGroceryList = (id: string | null): [GroceryList | null, Dispatch<SetStateAction<GroceryList | null>>] => {

  const [result, setResult] = useState<GroceryList | null>(null);
  const token = useAuthToken();

  useEffect(() => {
    if (token == null) {
      return;
    }
    if (id == null) {
      return;
    }
    (async () => {
      try {
        const groceryList = await new GroceryListsApi(token).getEntity(id);
        setResult(groceryList);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    })();
  }, [token, setResult, id]);

  return [result, setResult];
};