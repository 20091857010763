import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import Recipe from "domain/models/recipes/Recipe";
import moment, {Moment} from "moment/moment";
import {useTranslation} from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import React, {useCallback, useEffect, useState} from "react";
import RecipesGrid from "components/recipes/RecipesGrid";
import {useTheme} from "@mui/material/styles";

const toRecipeMap = (mealPlans: DailyMealPlan[], date: Moment): Map<string, Recipe> => {
  return mealPlans
      .filter(mealPlan => moment(mealPlan.date).isSame(date, "day"))
      .map(mealPlan => mealPlan.recipe)
      .reduce((map, recipe) => {
        map.set(recipe.id, recipe);
        return map;
      }, new Map());
};

interface CreateDailyMealPlanDialogProps {
  opened: boolean;
  mealPlans: DailyMealPlan[]
  recipes: Recipe[]
  date: Moment;
  onClose: () => Promise<void>;
  onSave: (date: Moment, newMealPlans: DailyMealPlan[]) => Promise<void>;
}

const CreateDailyMealPlanDialog = (
    {
      opened,
      mealPlans,
      recipes,
      date,
      onClose,
      onSave
    }: CreateDailyMealPlanDialogProps
): JSX.Element => {

  const {t} = useTranslation();
  const theme = useTheme();

  const [selectedRecipes, setSelectedRecipes] = useState<Map<string, Recipe>>(new Map());

  useEffect(() => {
    setSelectedRecipes(toRecipeMap(mealPlans, date));
  }, [setSelectedRecipes, mealPlans, date])

  const handleClose = useCallback(async () => {
    await onClose();
  }, [onClose]);

  const handleSave = async () => {
    const recipes: Recipe[] = Array.from(selectedRecipes.values());
    await onClose();
    await onSave(date, recipes.map(recipe => ({recipe, date: date.toISOString()})));
  };

  const handleRecipeClick = useCallback((recipe: Recipe) => {
    const recipeId = recipe.id;
    if (recipeId === undefined) {
      return;
    }
    setSelectedRecipes((selectedRecipes) => {
      const updatedRecipes = new Map(selectedRecipes);
      if (updatedRecipes.has(recipeId)) {
        updatedRecipes.delete(recipeId);
      } else {
        updatedRecipes.set(recipeId, recipe);
      }
      return updatedRecipes;
    });
  }, [setSelectedRecipes]);

  const isSelectedPredicate = useCallback((recipe: Recipe): boolean => {
    return recipe.id !== undefined && selectedRecipes.has(recipe.id);
  }, [selectedRecipes]);

  return (
      <Dialog
          open={opened}
          onClose={handleClose}
          fullWidth
          maxWidth={false}
      >
        <DialogTitle>{t("mealPlan.labelWithDate", {date: date.format("LL")})}</DialogTitle>

        <DialogContent>
          <Box sx={{mt: theme.spacing(1)}}>
            <RecipesGrid
                loading={false}
                recipes={recipes}
                handleClick={handleRecipeClick}
                isSelected={isSelectedPredicate}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{m: theme.spacing(2)}}>
          <Button
              variant="outlined"
              color="inherit"
              startIcon={<CancelIcon/>}
              onClick={handleClose}
          >
            {t("actions.cancel")}
          </Button>
          <Button
              variant="contained"
              startIcon={<SaveIcon/>}
              onClick={handleSave}
          >
            {t("actions.save")}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default CreateDailyMealPlanDialog;