import axios from "axios";
import {AuthenticatedParametrizedRequestConfig, AuthenticatedRequestConfig} from "services/AuthenticatedRequestConfig";

export interface EntitiesList<D> {
  list: D[];
}

export interface PaginatedEntitiesList<D> extends EntitiesList<D> {
  offset: number;
  limit: number;
  total: number;
}

export interface Entity {
  id?: string;
}

export class CrudApi<R extends Entity> {

  protected readonly token: string;
  protected readonly path: string;

  constructor(token: string, path: string) {
    this.token = token;
    this.path = path;
  }

  protected async createEntity(entity: R): Promise<R> {
    const createdEntityResponse = await axios.request(
        new AuthenticatedRequestConfig("post", this.path, this.token, entity)
    );
    return createdEntityResponse.data as R;
  }

  protected async getEntities(): Promise<R[]> {
    const getEntitiesResponse = await axios.request(
        new AuthenticatedRequestConfig("get", this.path, this.token)
    );
    const data = getEntitiesResponse.data as EntitiesList<R>;
    return data.list;
  }

  protected async getPaginatedEntities(offset: number, limit: number): Promise<PaginatedEntitiesList<R>> {
    const params = new URLSearchParams();
    params.append("offset", offset.toString());
    params.append("limit", limit.toString());
    const getEntitiesResponse = await axios.request(
        new AuthenticatedParametrizedRequestConfig("get", this.path, this.token, params),
    );
    return getEntitiesResponse.data as PaginatedEntitiesList<R>;
  }

  protected async getEntity(id: string): Promise<R | null> {
    if (!id) {
      return null;
    }
    const getEntityResponse = await axios.request(
        new AuthenticatedRequestConfig("get", `${this.path}/${id}`, this.token)
    );
    return getEntityResponse.data as R;
  }

  protected async updateEntity(entity: R): Promise<R | null> {
    if (!entity.id) {
      return null;
    }
    const updatedEntityResponse = await axios.request(
        new AuthenticatedRequestConfig("put", `${this.path}/${entity.id}`, this.token, entity)
    );
    return updatedEntityResponse.data as R;
  }

  protected async deleteEntity(id: string): Promise<void> {
    if (!id) {
      return;
    }
    await axios.request(
        new AuthenticatedRequestConfig("delete", `${this.path}/${id}`, this.token)
    );
  }
}