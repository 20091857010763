import React, {useState} from "react";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import {useAuthToken} from "hooks/use-auth-token";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "hooks/use-snackbar";
import {Box, Button, Card, CardContent, Container, Grid, Snackbar, TextField} from "@mui/material";
import MealPlanGenerator, {buildDefaultMealPlanGeneratorOptions, MealPlanGeneratorOptions} from "domain/logic/meal-plans/MealPlanGenerator";
import {MealPlansApi} from "services/apis/MealPlansApi";
import {useGetDailyMealPlans} from "hooks/plans/meal-plans-hooks";
import {useGetRecipes} from "hooks/recipes/recipes-hooks";
import MaterialNumberField from "components/commons/MaterialNumberField";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {Moment} from "moment";

const MealPlanGenerationView = (): JSX.Element => {

  const token: string | null = useAuthToken();

  const {t} = useTranslation();
  const history = useHistory();
  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();

  const [mealPlans] = useGetDailyMealPlans();
  const [recipes] = useGetRecipes();

  const [options, setOptions] = useState<MealPlanGeneratorOptions>(buildDefaultMealPlanGeneratorOptions());

  const handleChange = async (name: string, value: any): Promise<void> => {
    setOptions({
      ...options,
      [name]: value
    });
  };

  const handleDateChange = async (name: string, value: Moment | null): Promise<void> => {
    if (value !== null) {
      await handleChange(name, value);
    }
  };

  const handleNumberChange = async (name: string, value: number): Promise<void> => {
    await handleChange(name, value);
  };

  const handleSave = async (): Promise<void> => {

    const mealPlan: DailyMealPlan[] = new MealPlanGenerator(recipes)
        .generateMealPlan(mealPlans, options);

    try {
      await saveMealPlan(mealPlan);
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
    }

    history.replace("/mealPlans");
  };

  const saveMealPlan = async (mealPlans: DailyMealPlan[]) => {
    if (token === null) {
      return;
    }
    if (mealPlans.length !== 0) {
      await new MealPlansApi(token).createEntities(mealPlans);
    }
  };

  function buildFieldId(id: string): string {
    return `meal-plan-gen-${id}`;
  }

  return (
      <Container>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />
        <form>
          <Grid
              container
              direction="column"
              spacing={3}
          >
            <Grid item>
              <Card>
                <CardContent>
                  <Grid
                      container
                      direction="column"
                      spacing={3}
                  >
                    <Grid
                        item
                        container
                        direction="row"
                        spacing={3}
                    >
                      <Grid item xs={6}>
                        <MobileDatePicker
                            label={t("meaPlanGeneration.from")}
                            inputFormat="YYYY-DD-MM"
                            value={options.from}
                            onChange={(value) => handleDateChange("from", value)}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MobileDatePicker
                            label={t("meaPlanGeneration.to")}
                            inputFormat="YYYY-DD-MM"
                            value={options.to}
                            onChange={(value) => handleDateChange("to", value)}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <MaterialNumberField
                          id={buildFieldId("mealPerDay")}
                          label={t("meaPlanGeneration.mealPerDay")}
                          name="mealPerDay"
                          onChange={handleNumberChange}
                          value={options.mealPerDay}
                          min={0}
                      />
                    </Grid>
                    <Grid item>
                      <MaterialNumberField
                          id={buildFieldId("repeatDelay")}
                          label={t("meaPlanGeneration.repeatDelay")}
                          name="repeatDelay"
                          onChange={handleNumberChange}
                          value={options.repeatDelay}
                          min={0}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
              >
                <Box sx={{transform: "none"}}>
                  <Button
                      variant="contained"
                      onClick={handleSave}
                  >
                    {t("actions.generate")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
  );
};

export default MealPlanGenerationView;
