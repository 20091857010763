import {useAuthToken} from "hooks/use-auth-token";
import Recipe from "domain/models/recipes/Recipe";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {RecipesApi} from "services/apis/RecipesApi";

export const useGetRecipes = (): [Recipe[], Dispatch<SetStateAction<Recipe[]>>, boolean] => {

  const [result, setResult] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const token = useAuthToken();

  useEffect(() => {
    if (token == null) {
      return;
    }
    (async () => {
      try {
        const recipes = await new RecipesApi(token).getEntities();
        recipes.sort((thisRecipe: Recipe, thatRecipe: Recipe) => {
          return thisRecipe.name.localeCompare(thatRecipe.name);
        });
        setResult(recipes);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [token, setResult, setLoading]);

  return [result, setResult, loading];
};

export const useGetRecipe = (id: string | null): Recipe | null => {

  const [result, setResult] = useState<Recipe | null>(null);
  const token = useAuthToken();

  useEffect(() => {
    if (token == null || id === null) {
      return;
    }
    (async () => {
      try {
        setResult(await new RecipesApi(token).getEntity(id));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    })();
  }, [token, setResult, id]);

  return result;
};