import React, {createRef, Ref, useCallback, useEffect, useState} from "react";
import {alpha, Box, Grid, Snackbar, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import FullCalendar, {EventContentArg, EventInput} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {DateClickArg} from "@fullcalendar/interaction"
import moment from "moment/moment";
import {styled, Theme} from "@mui/material/styles";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import RecipeCard from "components/recipes/RecipeCard";
import {useGetRecipes} from "hooks/recipes/recipes-hooks";
import {useGetDailyMealPlans} from "hooks/plans/meal-plans-hooks";
import {useAuthToken} from "hooks/use-auth-token";
import {MealPlansApi} from "services/apis/MealPlansApi";
import {useSnackbar} from "hooks/use-snackbar";
import CreateDailyMealPlanDialog from "components/planner/CreateDailyMealPlanDialog";
import {Moment} from "moment";
import {EventApi, EventDropArg} from "@fullcalendar/common";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useHistory} from "react-router-dom";
import {GroceryList} from "domain/models/groceries/GroceryList";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import GroceryListGenerator, {buildDefaultGroceryListGeneratorOptions} from "domain/logic/grocery-lists/GroceryListGenerator";
import MealPlanGenerator, {buildDefaultMealPlanGeneratorOptions} from "domain/logic/meal-plans/MealPlanGenerator";
import DropdownButton from "components/commons/DropdownButton";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

const primaryBorder = (theme: Theme) => {
  return {
    border: `1px solid ${theme.palette.primary.main}`
  };
};

const primaryButton = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.primary.main,
    border: "none"
  };
};

const primaryButtonFocuses = {
  backgroundColor: "rgb(161, 56, 0)",
  border: "none"
};

export const StyleWrapper = styled(Box)(({theme}) => ({
  ".fc .fc-daygrid-day.fc-day-today": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.7),
  },
  ".fc-theme-standard": {
    "table": primaryBorder(theme),
    ".fc-scrollgrid": {
      borderCollapse: "collapse"
    },
    td: primaryBorder(theme),
    th: primaryBorder(theme),
    tr: primaryBorder(theme),
  },
  ".fc-button.fc-prev-button": primaryButton(theme),
  ".fc-button.fc-next-button": primaryButton(theme),
  ".fc-button.fc-button-primary": primaryButton(theme),
  ".fc-button.fc-prev-button:hover": primaryButtonFocuses,
  ".fc-button.fc-next-button:hover": primaryButtonFocuses,
  ".fc-button.fc-button-primary:hover": primaryButtonFocuses,
  ".fc-button.fc-prev-button:focus": primaryButtonFocuses,
  ".fc-button.fc-next-button:focus": primaryButtonFocuses,
  ".fc-button.fc-button-primary:focus": primaryButtonFocuses,
  ".fc-button.fc-prev-button:focus-visible": primaryButtonFocuses,
  ".fc-button.fc-next-button:focus-visible": primaryButtonFocuses,
  ".fc-button.fc-button-primary:focus-visible": primaryButtonFocuses,
  ".fc-button.fc-button-primary.fc-button-active": primaryButtonFocuses,
}));

const convertToEventSource = (mealPlans: DailyMealPlan[]): EventInput[] => {
  return mealPlans
      .map(plan => {
        return {
          mealPlanId: plan.id,
          title: plan.recipe.name,
          date: moment(plan.date).toDate(),
          recipe: plan.recipe,
        };
      });
};

const convertEventToMealPlan = (event: EventApi): DailyMealPlan => {
  return {
    id: event.extendedProps.mealPlanId,
    date: moment(event.start).toISOString(),
    recipe: event.extendedProps.recipe,
  };
};

const MealPlannerView = (): JSX.Element => {

  const token = useAuthToken();
  const history = useHistory();
  const {t} = useTranslation();
  const theme = useTheme();
  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();
  const [openMealPlanModal, setOpenMealPlanModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const calendarRef: Ref<FullCalendar> = createRef<FullCalendar>();
  const [mealPlans, setMealPlans] = useGetDailyMealPlans();
  const [recipes] = useGetRecipes();

  const generateGroceryList = async () => {
    history.push("/groceryLists/generate");
  };

  const automaticallyGenerateGroceryList = useCallback(async () => {
    if (token === null) {
      return;
    }
    const generatedGroceryList: GroceryList = new GroceryListGenerator()
        .generateGroceryList(buildDefaultGroceryListGeneratorOptions(), mealPlans);

    const savedGroceryList: GroceryList = await new GroceryListsApi(token)
        .createEntity(generatedGroceryList);

    history.push(`/groceryLists/${savedGroceryList.id}`);

  }, [history, mealPlans, token]);

  const generateMealPlan = async () => {
    history.push("/mealPlans/generate");
  };

  const quickGenerateMealPlan = async () => {
    const mealPlan: DailyMealPlan[] = new MealPlanGenerator(recipes)
        .generateMealPlan(mealPlans, buildDefaultMealPlanGeneratorOptions(moment().startOf("day")));

    await saveMealPlan(mealPlan);
    await refreshEvents();
  };

  const refreshEvents = useCallback(async () => {
    if (token === null) {
      return;
    }

    try {
      const dailyMealPlans = await new MealPlansApi(token).getEntities();
      setMealPlans(dailyMealPlans);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }, [setMealPlans, token]);

  const handleError = useCallback((error: Error) => {
    showSnackbar(error.message);
  }, [showSnackbar]);

  const handleDeleteMultiple = useCallback(async (mealPlanIds: string[], updateModel = true) => {
    if (token === null) {
      return;
    }

    try {
      const uniqueIds = new Set(mealPlanIds);

      const updatedList = mealPlans.filter(mealPlan => mealPlan.id && !uniqueIds.has(mealPlan.id));
      if (updateModel) {
        setMealPlans(updatedList);
      }
      await Promise.all(mealPlanIds.map(async (mealPlanId) => new MealPlansApi(token).deleteEntity(mealPlanId)));
    } catch (error) {
      if (error instanceof Error) {
        handleError(error);
      }
    }
  }, [mealPlans, setMealPlans, handleError, token]);

  const handleDelete = useCallback(async (mealPlanId: string) => {
    await handleDeleteMultiple([mealPlanId]);
  }, [handleDeleteMultiple]);

  const formatEventAsCard = useCallback((theme: Theme, args: EventContentArg) => {
    return (
        <Box sx={{marginBottom: theme.spacing(2)}}>
          <RecipeCard
              recipe={args.event.extendedProps.recipe}
              handleDelete={async () => {
                await handleDelete(args.event.extendedProps.mealPlanId);
                args.event.remove();
              }}
          />
        </Box>
    );
  }, [handleDelete]);

  const handleDateClick = (arg: DateClickArg) => {
    setSelectedDate(moment(arg.date));
    setOpenMealPlanModal(true);
  };

  const handleEventDrop = async (arg: EventDropArg) => {
    if (token === null) {
      return;
    }
    const updatedMealPlan = convertEventToMealPlan(arg.event);
    await new MealPlansApi(token).updateEntity(updatedMealPlan);
    await refreshEvents();
  };

  const saveMealPlan = useCallback(async (mealPlans: DailyMealPlan[]) => {
    if (token === null) {
      return;
    }

    if (mealPlans.length !== 0) {
      try {
        await new MealPlansApi(token).createEntities(mealPlans);
      } catch (error) {
        if (error instanceof Error) {
          handleError(error);
        }
      }
    }
  }, [handleError, token]);

  const saveDailyMealPlan = useCallback(async (date: Moment, newMealPlans: DailyMealPlan[]) => {
    await handleDeleteMultiple(
        mealPlans
            .filter(mealPlan => moment(mealPlan.date).isSame(date))
            .filter(mealPlan => mealPlan.id !== undefined)
            .map(mealPlan => mealPlan.id as string),
        false
    );
    await saveMealPlan(newMealPlans);
    await refreshEvents();
  }, [handleDeleteMultiple, mealPlans, refreshEvents, saveMealPlan]);

  /*
   * Refresh the calendar on mount and every time the meal plans change.
   */
  useEffect(() => {
    if (calendarRef.current === null) {
      return;
    }
    const calendarApi = calendarRef.current.getApi();
    calendarApi.removeAllEvents();
    for (const newEvent of convertToEventSource(mealPlans)) {
      calendarApi.addEvent(newEvent)
    }
  }, [refreshEvents, calendarRef, mealPlans]);

  return (
      <>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />

        <Grid
            container
            spacing={2}
        >
          <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent="flex-end"
          >
            <Grid item xs="auto">
              <DropdownButton
                  mainAction={{
                    label: t("mealPlan.generateGroceryList"),
                    icon: (<PlaylistAddIcon/>),
                    onClick: generateGroceryList,
                  }}
                  additionalActions={[{
                    label: t("mealPlan.automaticallyGenerateGroceryList"),
                    onClick: automaticallyGenerateGroceryList,
                  }]}
              />
            </Grid>
            <Grid item xs="auto">
              <DropdownButton
                  mainAction={{
                    label: t("mealPlan.generateMealPlan"),
                    icon: (<SettingsSuggestIcon/>),
                    onClick: quickGenerateMealPlan,
                  }}
                  additionalActions={[{
                    label: t("mealPlan.configureAndGenerateMealPlan"),
                    onClick: generateMealPlan,
                  }]}
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <StyleWrapper>
              <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridWeek"
                  height={1051}
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "dayGridWeek,dayGridMonth"
                  }}
                  buttonText={{
                    dayGridWeek: t("week"),
                    dayGridMonth: t("month")
                  }}
                  displayEventTime={false}
                  eventDisplay={"block"}
                  eventContent={(eventArgs) => formatEventAsCard(theme, eventArgs)}
                  eventColor="transparent"
                  dateClick={handleDateClick}
                  editable={true}
                  eventDrop={handleEventDrop}
                  firstDay={1}
              />
              <CreateDailyMealPlanDialog
                  mealPlans={mealPlans}
                  recipes={recipes}
                  opened={openMealPlanModal}
                  date={selectedDate}
                  onClose={async () => setOpenMealPlanModal(false)}
                  onSave={saveDailyMealPlan}
              />
            </StyleWrapper>
          </Grid>
        </Grid>

      </>
  );
};

export default MealPlannerView;
