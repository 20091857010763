import {useAuthToken} from "hooks/use-auth-token";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import {MealPlansApi} from "services/apis/MealPlansApi";
import moment from "moment";

export const useGetDailyMealPlans = (): [DailyMealPlan[], Dispatch<SetStateAction<DailyMealPlan[]>>, boolean] => {

  const [result, setResult] = useState<DailyMealPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const token = useAuthToken();

  useEffect(() => {
    if (token == null) {
      return;
    }
    (async () => {
      try {
        const dailyMealPlans = await new MealPlansApi(token).getEntities();
        dailyMealPlans.sort((thisPlan, thatPlan) =>
            moment(thisPlan.date).diff(moment(thatPlan.date), "days")
        );
        setResult(dailyMealPlans);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [token, setResult]);

  return [result, setResult, loading];
};