import React, {ChangeEvent, useEffect, useState} from "react";
import {TextField} from "@mui/material";

interface MaterialTextFieldProps {
  id: string;
  name: string;
  label: string;
  value: number;
  min?: number;
  max?: number;

  onChange(name: string, value: number): Promise<void>;
}

const MaterialNumberField = (props: MaterialTextFieldProps): JSX.Element => {

  const [value, setValue] = useState<number>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value, setValue]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(e.target.value);
    setValue(newValue);
    if (props.onChange) {
      await props.onChange(props.name, newValue);
    }
  };

  return (
      <TextField
          id={props.id}
          label={props.label}
          value={value}
          name={props.name}
          onChange={handleChange}
          fullWidth
          type="number"
          InputProps={{
            inputProps: {
              min: props.min
            }
          }}
      />
  );
};

export default MaterialNumberField;