import React, {useCallback, useState} from "react";
import {BrowserRouter, Link, Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles} from "tss-react/mui";
import {AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, Toolbar, Typography} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {Theme} from "@mui/material/styles";
import {MenuEntry} from "domain/models/navigation-menu/MenuEntry";
import {menu} from "domain/models/navigation-menu/Menu";
import IconSwitch from "./IconSwitch";
import RecipesView from "components/recipes/RecipesView";
import RecipesEditView from "components/recipes/RecipesEditView";
import MealPlannerView from "components/planner/MealPlannerView";
import MenuItem from "@mui/material/MenuItem";
import {useAuth0} from "@auth0/auth0-react";
import GroceryListsMasterDetailsView from "components/groceries/GroceryListsMasterDetailsView";
import MealPlanGenerationView from "components/planner/MealPlanGenerationView";
import GroceryListGenerationView from "components/planner/GroceryListGenerationView";

const drawerWidth = 240;

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: "flex"
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: `${drawerWidth}px !important`,
        width: `calc(100% - ${drawerWidth}px) !important`
      },
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none !important"
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth
      },
    },
    drawerTemporary: {
      [theme.breakpoints.up("xs")]: {
        display: "block"
      },
      [theme.breakpoints.up("sm")]: {
        display: "none"
      },
    },
    drawerPermanent: {
      [theme.breakpoints.up("xs")]: {
        display: "none"
      },
      [theme.breakpoints.up("sm")]: {
        display: "block"
      },
    },
    drawerWrapper: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    main: {
      flexGrow: 1,
      p: 3,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    colorWhite: {
      color: "white",
    },
    logoLink: {
      color: "inherit",
      textDecoration: "none"
    },
    contentWrapper: {
      margin: "16px"
    }
  };
});

interface SimpleMenuItemProps {
  menuEntry: MenuEntry;
}

const SimpleMenuItem = ({menuEntry}: SimpleMenuItemProps): JSX.Element => {

  const {t} = useTranslation();

  return (
      <ListItem button component={Link} to={menuEntry.path}>
        <ListItemIcon>
          <IconSwitch iconType={menuEntry.iconType}/>
        </ListItemIcon>
        <ListItemText primary={t(menuEntry.textKey)}/>
      </ListItem>
  );
};

const ContentRouter = (): JSX.Element => {

  const {classes} = useStyles();

  return (
      <Box className={classes.contentWrapper}>
        <Switch>
          <Route exact={true} path="/">
            <Redirect to="/recipes"/>
          </Route>

          <Route exact={true} path="/recipes">
            <RecipesView/>
          </Route>
          <Route exact={true} path="/recipes/edit">
            <RecipesEditView/>
          </Route>
          <Route
              exact={true}
              path="/recipes/edit/:recipeId"
              render={componentProps => (
                  <RecipesEditView recipeId={componentProps.match.params.recipeId} {...componentProps} />
              )}
          />

          <Route exact={true} path="/mealPlans">
            <MealPlannerView/>
          </Route>

          <Route exact={true} path="/mealPlans/generate">
            <MealPlanGenerationView/>
          </Route>

          <Route exact={true} path="/groceryLists">
            <GroceryListsMasterDetailsView/>
          </Route>

          <Route exact={true} path="/groceryLists/generate">
            <GroceryListGenerationView/>
          </Route>

          <Route exact={true} path="/groceryLists/:groceryListId">
            <GroceryListsMasterDetailsView/>
          </Route>

        </Switch>
      </Box>
  );
};

const DrawerMenu = (): JSX.Element => {

  const {t} = useTranslation();

  const {logout} = useAuth0();

  const [mobileOpen, setMobileOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const {classes, cx} = useStyles();

  const drawer = (
      <div>
        <Toolbar/>
        <Divider/>
        <List className={classes.colorWhite}>
          {menu.map((menuEntry) => (
              <SimpleMenuItem key={menuEntry.path} menuEntry={menuEntry}/>
          ))}
        </List>
      </div>
  );

  const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogout = useCallback(async () => {
    await logout({returnTo: window.location.origin});
    handleCloseUserMenu();
  }, [handleCloseUserMenu, logout]);

  return (
      <Box className={classes.root}>
        <BrowserRouter>
          <CssBaseline/>
          <AppBar
              position="fixed"
              className={classes.appBar}
          >
            <Toolbar>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
              >
                <MenuIcon/>
              </IconButton>

              <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                <Link
                    className={classes.logoLink}
                    aria-label="go to home"
                    to="/">
                  {t("pantree")}
                </Link>
              </Typography>

              <div>
                <IconButton
                    size="large"
                    aria-label="user account"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                    color="inherit"
                >
                  <AccountCircleIcon/>
                </IconButton>
                <Menu
                    sx={{marginTop: "45px"}}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleLogout}>
                    {t("actions.logout")}
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          <Box
              component="nav"
              className={classes.drawerWrapper}
              aria-label="mailbox folders"
          >
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                className={cx(classes.drawer, classes.drawerTemporary)}
            >
              {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                className={cx(classes.drawer, classes.drawerPermanent)}
                open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
              component="main"
              className={classes.main}
          >
            <Toolbar/>
            <ContentRouter/>
          </Box>
        </BrowserRouter>
      </Box>
  );
};

export default DrawerMenu;