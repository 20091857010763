import {Button, Card, IconButton, List, ListItem, ListItemButton, ListItemText, Pagination, Skeleton, TextField} from "@mui/material";
import moment from "moment";
import React, {ChangeEvent, KeyboardEvent, useCallback, useEffect, useState} from "react";
import {buildGroceryList, GroceryList} from "domain/models/groceries/GroceryList";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import {GroceryListsApi} from "services/apis/GroceryListsApi";
import {useAuthToken} from "hooks/use-auth-token";
import {useTranslation} from "react-i18next";
import {useGetPaginatedGroceryLists} from "hooks/groceries/grocery-lists-hooks";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {useHistory} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

interface GroceryListsMasterProps {
  autoSelect: boolean;

  selectedGroceryListId: string | null;

  onClick(groceryList: GroceryList): void;

  onSave(groceryList: GroceryList): void;
}

const resultsPerPage = 7;

const GroceryListsMaster = ({autoSelect, selectedGroceryListId, onClick, onSave}: GroceryListsMasterProps): JSX.Element => {

  const token: string | null = useAuthToken();
  const history = useHistory();
  const theme = useTheme();

  const {t} = useTranslation();

  const [offset, setOffset] = useState<number>(0);

  const [groceryLists, setGroceryLists, refreshGroceryLists, , firstLoadingGroceryLists] = useGetPaginatedGroceryLists(offset, resultsPerPage);
  const [editedRow, setEditedRow] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    if (autoSelect && groceryLists.list.length > 0) {
      onClick(groceryLists.list[0]);
    } else {
      history.replace("/groceryLists");
    }
  }, [groceryLists, onClick, autoSelect, history]);

  useEffect(() => {
    setTotalPages(Math.ceil(groceryLists.total / resultsPerPage));
  }, [groceryLists]);

  const handleUpdate = useCallback(async (updatedGroceryList: GroceryList) => {

    setEditedRow(null);

    await onSave(updatedGroceryList);

    await refreshGroceryLists();
  }, [onSave, refreshGroceryLists]);

  const handleDelete = useCallback(async (groceryList: GroceryList) => {
    if (token === null) {
      return;
    }
    if (!groceryList.id) {
      return;
    }
    try {
      await new GroceryListsApi(token).deleteEntity(groceryList.id);
      await refreshGroceryLists();
    } catch (error) {
      console.error(error);
    }
  }, [token, refreshGroceryLists]);

  const handleNew = useCallback(async () => {
    if (token === null) {
      return;
    }
    try {
      const groceryList: GroceryList | null = await new GroceryListsApi(token)
          .createEntity(buildGroceryList(t("groceryLists.defaultName")));
      if (groceryList !== null) {
        await refreshGroceryLists();
      }
    } catch (error) {
      console.error(error);
    }
  }, [token, t, refreshGroceryLists]);

  const handleNameChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (editedRow === null) {
      return;
    }

    const changedRow = editedRow;
    setGroceryLists((groceryListsWrapper) => {
      const newGroceryList = [...groceryListsWrapper.list];
      const newValue: string = e.target.value;
      newGroceryList[changedRow] = {
        ...newGroceryList[changedRow],
        name: newValue,
      }
      return {
        ...groceryListsWrapper,
        list: newGroceryList,
      };
    });
  }, [editedRow, setGroceryLists]);

  const handleEdit = useCallback(async (index: number) => {
    setEditedRow(index);
  }, [setEditedRow]);

  const handleKeyDown = async (e: KeyboardEvent, groceryList: GroceryList) => {
    if (e && e.key === "Enter") {
      await handleUpdate(groceryList);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setOffset((pageNumber - 1) * resultsPerPage);
  };

  return (
      <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="flex-end"
      >
        <Grid item xs="auto">
          <Button
              variant="contained"
              startIcon={<AddIcon/>}
              onClick={handleNew}
          >
            {t("groceryLists.groceryList")}
          </Button>
        </Grid>

        <Grid item xs>
          {groceryLists.list.length > 0 && (
              <Card>
                <List>
                  {firstLoadingGroceryLists
                      ? Array.from(Array(resultsPerPage).keys()).map((n: number) => (
                          <ListItem
                              key={n}
                              disablePadding
                              secondaryAction={
                                <>
                                  <Skeleton animation="wave" variant="circular" width={40} height={40}/>
                                </>
                              }
                          >
                            <ListItemText
                                primary={<Skeleton animation="wave" height={20} width="80%" style={{margin: theme.spacing(0.7), marginLeft: theme.spacing(2)}}/>}
                                secondary={<Skeleton animation="wave" height={20} width="40%" style={{margin: theme.spacing(0.9), marginLeft: theme.spacing(2)}}/>}
                            />
                          </ListItem>
                      ))
                      : groceryLists.list.map((groceryList: GroceryList, index: number) => (
                          <ListItem
                              key={groceryList.id}
                              disablePadding
                              secondaryAction={
                                <>
                                  {(editedRow !== null && editedRow === index)
                                      ? (
                                          <IconButton
                                              aria-label="save"
                                              onClick={() => handleUpdate(groceryList)}
                                          >
                                            <SaveIcon/>
                                          </IconButton>
                                      )
                                      : (
                                          <IconButton
                                              aria-label="edit"
                                              onClick={() => handleEdit(index)}
                                          >
                                            <EditIcon/>
                                          </IconButton>
                                      )
                                  }
                                  <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => handleDelete(groceryList)}
                                  >
                                    <DeleteIcon/>
                                  </IconButton>
                                </>
                              }
                          >
                            <ListItemButton onClick={() => onClick(groceryList)}>
                              {editedRow !== null && editedRow === index ? (
                                  <TextField
                                      id={`grocery-list-edit-${index}`}
                                      variant="standard"
                                      value={groceryList.name}
                                      onChange={handleNameChange}
                                      onKeyDown={async (e: KeyboardEvent) => await handleKeyDown(e, groceryList)}
                                      onBlur={async () => await handleUpdate(groceryList)}
                                  />
                              ) : (
                                  <ListItemText
                                      primary={groceryList.name}
                                      primaryTypographyProps={{
                                        fontWeight: selectedGroceryListId && selectedGroceryListId === groceryList.id ? "bold" : "normal"
                                      }}
                                      secondary={moment(groceryList.creationDate).format('LL HH:mm')}
                                  />
                              )}
                            </ListItemButton>
                          </ListItem>
                      ))}
                </List>
              </Card>
          )}
        </Grid>

        <Grid item xs>
          <Pagination
              count={totalPages}
              shape="rounded"
              defaultValue={1}
              onChange={handlePageChange}
          />
        </Grid>
      </Grid>
  );
};

export default GroceryListsMaster;