import React from "react";
import DrawerMenu from "components/menu/DrawerMenu";
import {Snackbar} from "@mui/material";
import {useSnackbar} from "hooks/use-snackbar";

const AppContent = (): JSX.Element => {

  // TODO implement single snackbar
  const [snackbarState, /*showSnackbar*/, closeSnackbar] = useSnackbar();

  return (
      <>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />
        <DrawerMenu/>
      </>
  );
};

export default AppContent;
