import React, {useEffect, useState} from "react";
import {Autocomplete, Box, Button, Card, CardContent, Container, Grid, Snackbar, TextField} from "@mui/material";
import MaterialTextField from "components/commons/MaterialTextField";
import {useTranslation} from "react-i18next";
import Recipe, {createDefaultRecipe, validateRecipe} from "domain/models/recipes/Recipe";
import {useHistory} from "react-router-dom";
import {RecipesApi} from "services/apis/RecipesApi";
import {useAuthToken} from "hooks/use-auth-token";
import {useGetRecipe, useGetRecipes} from "hooks/recipes/recipes-hooks";
import {useSnackbar} from "hooks/use-snackbar";

interface RecipesEditViewProps {
  recipeId?: string;
}

const RecipesEditView = (props: RecipesEditViewProps): JSX.Element => {

  const token: string | null = useAuthToken();

  const {t} = useTranslation();
  const history = useHistory();

  const [snackbarState, showSnackbar, closeSnackbar] = useSnackbar();

  const [recipe, setRecipe] = useState<Recipe>(createDefaultRecipe());
  const recipeResponse = useGetRecipe(props.recipeId || null);

  const [recipes,] = useGetRecipes();
  const [tags, setTags] = useState<string[]>([]);
  const [ingredients, setIngredients] = useState<string[]>([]);

  useEffect(() => {
    setTags(Array.from(new Set(recipes.flatMap(recipe => recipe.tags))).sort());
    setIngredients(Array.from(new Set(recipes.flatMap(recipe => recipe.ingredients))).sort());
  }, [recipes]);

  useEffect(() => {
    if (recipeResponse === null) {
      return;
    }
    setRecipe(recipeResponse);
  }, [recipeResponse, history, setRecipe]);

  const handleChange = async (name: string, value: any): Promise<void> => {
    setRecipe({
      ...recipe,
      [name]: value
    });
  };

  const handleSave = async (): Promise<void> => {
    if (token == null) {
      console.error("The auth token is null");
      return;
    }

    try {
      validateRecipe(recipe);
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
      return;
    }

    try {
      const recipeApi = new RecipesApi(token);
      if (recipe.id) {
        await recipeApi.updateEntity(recipe);
      } else {
        await recipeApi.createEntity(recipe);
      }
      history.replace("/recipes");
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar(error.message);
      }
    }
  };

  function buildFieldId(id: string): string {
    return `recipe-edit-view-${id}`;
  }

  return (
      <Container>
        <Snackbar
            anchorOrigin={snackbarState}
            open={snackbarState.open}
            onClose={() => closeSnackbar()}
            message={snackbarState.message}
            key={snackbarState.message}
        />
        <form>
          <Grid
              container
              direction="column"
              spacing={3}
          >
            <Grid item>
              <Card>
                <CardContent>
                  <Grid
                      container
                      direction="column"
                      spacing={3}
                  >
                    <Grid item>
                      <MaterialTextField
                          id={buildFieldId("name")}
                          name="name"
                          label={t("name")}
                          onChange={handleChange}
                          value={recipe.name}
                      />
                    </Grid>
                    <Grid item>
                      <MaterialTextField
                          id={buildFieldId("image-url")}
                          name="imageUrl"
                          label={t("recipes.imageUrl.label")}
                          onChange={handleChange}
                          value={recipe.imageUrl}
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                          id={buildFieldId("ingredients")}
                          multiple
                          options={ingredients}
                          freeSolo
                          value={recipe.ingredients}
                          onChange={(event: any, newIngredients: string[]) => handleChange("ingredients", newIngredients.sort())}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label={t("recipes.ingredients.label")}
                                  placeholder={t("recipes.ingredients.placeholder")}
                              />
                          )}
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                          id={buildFieldId("tags")}
                          multiple
                          options={tags}
                          freeSolo
                          value={recipe.tags}
                          onChange={(event: any, newTags: string[]) => handleChange("tags", newTags.sort())}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label={t("recipes.tags.label")}
                                  placeholder={t("recipes.tags.placeholder")}
                              />
                          )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
              >
                <Box sx={{transform: "none"}}>
                  <Button
                      variant="contained"
                      onClick={handleSave}
                  >
                    {t("actions.save")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
  );
};

export default RecipesEditView;
