import moment, {Moment} from "moment";
import {buildGroceryList, buildGroceryListItem, buildGroceryListName, GroceryList, GroceryListItem} from "domain/models/groceries/GroceryList";
import {DailyMealPlan} from "domain/models/plan/MealPlan";
import Recipe from "domain/models/recipes/Recipe";

interface IngredientSource {
  ingredient: string;
  recipe: Recipe;
}

export interface GroceryListGeneratorOptions {
  from: Moment;
  to: Moment;
}

export const buildDefaultGroceryListGeneratorOptions = (from: Moment = moment().startOf("day")): GroceryListGeneratorOptions => {
  return {
    from: moment(from),
    to: moment(from).add(2, "days"),
  };
};

export default class GroceryListGenerator {

  public generateGroceryList(options: GroceryListGeneratorOptions, currentPlan: DailyMealPlan[]): GroceryList {
    const listItems = this.extractIngredientsFromRecipes(
        this.extractRecipesFromMealPlan(options.from, options.to, currentPlan)
    )
    return buildGroceryList(buildGroceryListName(options), listItems);
  }

  private extractRecipesFromMealPlan(from: Moment, to: Moment, currentPlan: DailyMealPlan[]): Recipe[] {
    return currentPlan
        .filter((mealPlan: DailyMealPlan) => moment(mealPlan.date).isBetween(from, to, "days", "[]"))
        .map((mealPlan: DailyMealPlan) => mealPlan.recipe);
  }

  private extractIngredientsFromRecipes(mealPlanRecipes: Recipe[]): GroceryListItem[] {
    return Array.from(mealPlanRecipes
        .flatMap((recipe: Recipe) => recipe.ingredients.map(ingredient => ({ingredient, recipe})))
        .reduce((groupedByIngredients: Map<string, Recipe[]>, ingredientSource: IngredientSource) => {
          const recipes = groupedByIngredients.get(ingredientSource.ingredient) || [];
          recipes.push(ingredientSource.recipe);
          groupedByIngredients.set(ingredientSource.ingredient, recipes);
          return groupedByIngredients;
        }, new Map<string, Recipe[]>())
        .entries())
        .map((entry: [string, Recipe[]]) => buildGroceryListItem(entry[0], entry[1].map(recipe => recipe.name)))
  }
};