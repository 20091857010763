import React from "react";
import {styled} from "@mui/material/styles";
import {Fab} from "@mui/material";

const StyledFab = styled(Fab)(({theme}) => ({
  position: 'absolute',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
}));

interface PrimaryFabProps {
  onClick(): void;

  children?: React.ReactNode;
}

const PrimaryFab: React.FC<PrimaryFabProps> = (props) => {
  return (
      <StyledFab color='primary' onClick={props.onClick}>
        {props.children ? props.children : <React.Fragment/>}
      </StyledFab>
  );
};

export default PrimaryFab;