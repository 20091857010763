import React from "react";
import {Card, CardActionArea, CardHeader, Skeleton} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledCardHeader = styled(CardHeader)({
  maxHeight: "100px",
  overflow: "hidden",
  ".MuiCardHeader-content": {
    maxHeight: "100%",
    overflow: "hidden"
  }
});

const RecipeSkeletonCard = (): JSX.Element => {

  return (
      <StyledCard>
        <StyledCardHeader
            titleTypographyProps={{variant: "h6"}}
            title={
              <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{marginBottom: 6}}
              />
            }
        />

        <CardActionArea>
          <Skeleton
              sx={{height: 140}}
              animation="wave"
              variant="rectangular"
          />
        </CardActionArea>
      </StyledCard>
  );
};

export default RecipeSkeletonCard;
