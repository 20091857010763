import {Entity} from "services/apis/CrudApi";

export default interface Recipe extends Entity {
  userId?: string;
  name: string;
  imageUrl: string;
  ingredients: string[];
  tags: string[];
}

export const validateRecipe = (recipe: Recipe) => {
  if (!recipe.name) {
    throw new Error("Name should not be empty");
  }
  if (!recipe.imageUrl) {
    throw new Error("Image URL should not be empty");
  }
  if (!recipe.imageUrl.startsWith("https://")) {
    throw new Error("Please provide a link to a valid image");
  }
};

export const createDefaultRecipe = (): Recipe => {
  return {
    name: "",
    imageUrl: "https://",
    ingredients: [],
    tags: [],
  };
};