export interface ApiEndpointConfig {
  apiEndpointUrl: string;
}

export const backendConfig: ApiEndpointConfig = {
  apiEndpointUrl: process.env.REACT_APP_BACKEND_URL || "http://localhost:5000/v1",
};

export interface AuthConfig {
  domain: string;
  clientId: string;
  audience: string;
}

export const auth0Config: AuthConfig = {
  domain: "tinydraco.eu.auth0.com",
  clientId: "SaWCifz10SvB2jqlPbDLbTpSEcroYrnY",
  audience: "https://pantree-backend.derow.ch",
};
