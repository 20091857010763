import React from "react";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from "@mui/material/MenuItem";

export interface DropdownButtonAction {
  label: string;
  onClick: () => Promise<void>;
}

export interface MainDropdownButtonAction extends DropdownButtonAction {
  icon: React.ReactNode;
}

interface DropdownButtonProps {
  mainAction: MainDropdownButtonAction;
  additionalActions: DropdownButtonAction[];
}

const DropdownButton = (props: DropdownButtonProps): JSX.Element => {

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = async (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
  ) => {
    if (index < props.additionalActions.length) {
      await props.additionalActions[index].onClick();
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
      <>
        <ButtonGroup
            variant="contained"
            aria-label={`${props.mainAction.label} dropdown`}
            ref={anchorRef}
        >
          <Button
              variant="contained"
              startIcon={props.mainAction.icon}
              onClick={props.mainAction.onClick}
          >
            {props.mainAction.label}
          </Button>
          <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select meal plan generation"
              aria-haspopup="menu"
              onClick={handleToggle}
          >
            <ArrowDropDownIcon/>
          </Button>
        </ButtonGroup>
        <Popper
            sx={{
              zIndex: 10,
            }}
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
        >
          {({TransitionProps, placement}) => (
              <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {props.additionalActions.map((additionalAction, index) => (
                          <MenuItem
                              key={additionalAction.label}
                              onClick={(e) => handleMenuItemClick(e, index)}
                          >
                            {additionalAction.label}
                          </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>
      </>
  );
};

export default DropdownButton;