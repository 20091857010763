import {MenuEntry} from "domain/models/navigation-menu/MenuEntry";
import {IconType} from "domain/models/navigation-menu/IconType";

export const menu: MenuEntry[] = [
  {
    nameKey: "menu.recipes",
    textKey: "menu.recipes",
    path: "/recipes",
    iconType: IconType.MenuBook
  },
  {
    nameKey: "menu.mealPlanner",
    textKey: "menu.mealPlanner",
    path: "/mealPlans",
    iconType: IconType.RestaurantMenu
  },
  {
    nameKey: "menu.groceries",
    textKey: "menu.groceries",
    path: "/groceryLists",
    iconType: IconType.LocalGroceryStore
  },
];
