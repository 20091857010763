import {SnackbarOrigin} from "@mui/material";
import {useCallback, useState} from "react";

export interface SnackbarState extends SnackbarOrigin {
  open: boolean;
  message: string;
}

export const useSnackbar = (): [SnackbarState, (message: string) => void, () => void] => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: "",
    vertical: "top",
    horizontal: "right",
  });

  const showSnackbar = useCallback((message: string) => {
    setSnackbarState({
      ...snackbarState,
      open: true,
      message: message,
    });
  }, [snackbarState, setSnackbarState]);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({
      ...snackbarState,
      open: false,
      message: "",
    });
  }, [snackbarState, setSnackbarState]);

  return [snackbarState, showSnackbar, closeSnackbar];
};