import Recipe from "domain/models/recipes/Recipe";
import {CrudApi} from "services/apis/CrudApi";

export class RecipesApi extends CrudApi<Recipe> {
  constructor(token: string) {
    super(token, "recipes");
  }

  public async createEntity(entity: Recipe): Promise<Recipe> {
    return await super.createEntity(entity);
  }

  public async getEntities(): Promise<Recipe[]> {
    return await super.getEntities();
  }

  public async getEntity(id: string): Promise<Recipe | null> {
    return await super.getEntity(id);
  }

  public async updateEntity(entity: Recipe): Promise<Recipe | null> {
    return await super.updateEntity(entity);
  }

  public async deleteEntity(id: string): Promise<void> {
    await super.deleteEntity(id);
  }
}
