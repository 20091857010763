import {AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import {backendConfig} from "config";

export class AuthenticatedRequestConfig implements AxiosRequestConfig {
  public readonly method: string;
  public readonly url: string;
  public readonly headers: AxiosRequestHeaders;
  public readonly data?: any;

  constructor(method: string, path: string, bearerToken: string, data?: any) {
    this.method = method;
    if (backendConfig.apiEndpointUrl.endsWith("/")) {
      this.url = `${backendConfig.apiEndpointUrl}${path}`;
    } else {
      this.url = `${backendConfig.apiEndpointUrl}/${path}`;
    }
    this.headers = {
      "Authorization": `Bearer ${bearerToken}`,
    };
    this.data = data;
  }
}

export class AuthenticatedParametrizedRequestConfig extends AuthenticatedRequestConfig {
  public readonly params: URLSearchParams;

  constructor(method: string, path: string, bearerToken: string, params: URLSearchParams, data?: any) {
    super(method, path, bearerToken, data);
    this.params = params;
  }
}