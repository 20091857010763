import moment from "moment/moment";
import {GroceryListGeneratorOptions} from "domain/logic/grocery-lists/GroceryListGenerator";

export interface GroceryList {
  id?: string;
  userId?: string;
  name: string;
  creationDate: string;
  groceries: GroceryListItem[];
}

export interface GroceryListItem {
  name: string;
  quantity: number;
  unit: Unit;
  checked: boolean;
  sourceRecipes: string[];
}

type Unit = "mg" | "g" | "kg"
    | "ml" | "cl" | "dl" | "l"
    | "tsp" | "tbsp" | "fl oz" | "cup" | "fl pt" | "fl qt" | "gal"
    | "oz" | "lb"
    | "unit";


function validateGroceryListItem(item: GroceryListItem) {
  if (!item.name) {
    throw new Error("Name should not be empty");
  }
  item.quantity = item.quantity || 0;
  item.unit = item.unit || "unit";
}

export const validateGroceryList = (groceryList: GroceryList) => {
  if (!groceryList.name) {
    throw new Error("Name should not be empty");
  }
  if (!groceryList.creationDate) {
    groceryList.creationDate = moment().toISOString();
  }
  for (const item of groceryList.groceries) {
    validateGroceryListItem(item);
  }
};

export const buildGroceryList = (
    name: string,
    groceries: GroceryListItem[] = [],
): GroceryList => ({
  name,
  creationDate: moment().toISOString(),
  groceries,
});

export const buildGroceryListItem = (name: string = "???", sourceRecipes: string[] = []): GroceryListItem => ({
  name,
  quantity: 0,
  unit: "unit",
  checked: false,
  sourceRecipes,
});

export const buildGroceryListName = (options: GroceryListGeneratorOptions): string => {
  return `Grocery list for ${options.from.format("L")} to ${options.to.format("L")}`;
};