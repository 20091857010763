import React, {useCallback, useEffect, useState} from "react";
import {Card, CardActionArea, CardHeader, CardMedia, IconButton, ListItemIcon, ListItemText, Menu} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Recipe from "domain/models/recipes/Recipe";
import {styled} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledCardHeader = styled(CardHeader)({
  maxHeight: "100px",
  overflow: "hidden",
  ".MuiCardHeader-content": {
    maxHeight: "100%",
    overflow: "hidden"
  }
});

interface RecipeCardProps {
  recipe: Recipe
  handleDelete?: (recipe: Recipe) => void;
  isSelected?: (recipe: Recipe) => boolean;
  handleClick?: (recipe: Recipe) => void;
  handleEdit?: (recipe: Recipe) => void;
}

const RecipeCard = (
    {
      recipe,
      handleDelete,
      isSelected,
      handleClick,
      handleEdit,
    }: RecipeCardProps
): JSX.Element => {

  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasActions, setHasActions] = useState<boolean>(false);

  const handleOpenActionsMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleCloseActionsMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDeleteButtonClick = useCallback(() => {
    if (!handleDelete) {
      return;
    }
    handleDelete(recipe);
  }, [handleDelete, recipe]);

  const handleEditButtonClick = useCallback(() => {
    if (!handleEdit) {
      return;
    }
    handleEdit(recipe);
  }, [handleEdit, recipe]);

  const handleRecipeClick = useCallback(() => {
    if (!handleClick) {
      return;
    }
    handleClick(recipe);
  }, [handleClick, recipe]);

  useEffect(() => {
    setHasActions(handleDelete !== undefined || handleEdit !== undefined);
  }, [setHasActions, handleDelete, handleEdit]);

  return (
      <StyledCard>
        <StyledCardHeader
            action={<>
              {isSelected && isSelected(recipe) && (
                  <IconButton
                      onClick={handleRecipeClick}
                      aria-label="selected">
                    <CheckIcon/>
                  </IconButton>
              )}
              {hasActions && (
                  <IconButton
                      aria-label="actions"
                      onClick={handleOpenActionsMenu}
                  >
                    <MoreVertIcon/>
                  </IconButton>
              )}
            </>}
            titleTypographyProps={{variant: "h6"}}
            title={recipe.name}
        />
        <Menu
            sx={{marginTop: "45px"}}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseActionsMenu}
        >

          {handleEdit && (
              <MenuItem
                  aria-label="edit"
                  onClick={handleEditButtonClick}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  {t("actions.edit")}
                </ListItemText>
              </MenuItem>
          )}
          {handleDelete && (
              <MenuItem
                  aria-label="delete"
                  onClick={handleDeleteButtonClick}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  {t("actions.delete")}
                </ListItemText>
              </MenuItem>
          )}
        </Menu>

        <CardActionArea>
          <CardMedia
              onClick={handleRecipeClick}
              component="img"
              height="140"
              image={recipe.imageUrl}
              alt={recipe.name}
          />
        </CardActionArea>
      </StyledCard>
  );
};

export default RecipeCard;
