import {CrudApi, EntitiesList} from "services/apis/CrudApi";
import {DailyMealPlan} from "domain/models/plan/MealPlan"
import axios from "axios";
import {AuthenticatedRequestConfig} from "services/AuthenticatedRequestConfig";

export class MealPlansApi extends CrudApi<DailyMealPlan> {
  constructor(token: string) {
    super(token, "mealPlans");
  }

  public async createEntities(entities: DailyMealPlan[]): Promise<DailyMealPlan[]> {
    const entitiesList: EntitiesList<DailyMealPlan> = {
      list: entities
    };
    const createdEntitiesResponse = await axios.request(
        new AuthenticatedRequestConfig("post", `${this.path}/batch`, this.token, entitiesList)
    );
    const data = createdEntitiesResponse.data as EntitiesList<DailyMealPlan>;
    return data.list;
  }

  public async getEntities(): Promise<DailyMealPlan[]> {
    return await super.getEntities();
  }

  public async updateEntity(entity: DailyMealPlan): Promise<DailyMealPlan | null> {
    return await super.updateEntity(entity);
  }

  public async deleteEntity(id: string): Promise<void> {
    await super.deleteEntity(id);
  }
}
